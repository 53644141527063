<template>
    <v-layout style="flex: 1;">
        <div class="box-group">
            <div class="box-info">
                <div class="box-title">
                    {{ xrayNm }} X-ray
                    <span :class="pineDx === 'S' ? `br` : ``">
                        이미지 등록
                        <div v-if="xrayMap && xrayMap.no" class="change-button" @click="$refs.fileInput.click()" v-ripple>
                            <span class="button-text">이미지 변경</span>
                        </div>
                    </span>
                </div>
                <div class="btnW" style="width:auto;">
                    <input type="file" ref="fileInput" accept=".jpg, .png, .dcm" key="fileInput" @change="handleFileUpload($event)" style="display: none;">

                    <div v-if="(xrayMap && xrayMap.no) && showPictureDt" class="file-and-date">
                        <div class="file-name">
                            <label for="fileName" class="fileLabel">파일명:</label>
                            <div class="file-name-background">
                                <span id="fileName">{{ selectedFileName }}</span>
                            </div>
                        </div>
                        <div class="dateInpW">
                            <label for="pictureDt" class="dateLabel">촬영일:</label>
                            <input type="date" id="pictureDt" class="dateInp" v-model="pictureDt" @change="$emit('chgPictureDt', pictureDt)" :max="getToDay()">
                        </div>
                    </div>
                    <div v-else class="upload-button" v-ripple @click="$refs.fileInput.click()" :id="xrayType == XRAY_TYPE_SPINE ? 'gd_file_up' : ''">
                        <span class="button-text">파일 업로드</span>
                        <span class="button-icon">
                            <img src="../assets/upload.png" alt="">
                        </span>
                    </div>
                </div>
            </div>
            <div class="box-content" v-ripple tabindex="0" @click="fileUploadEvent()"
                :style="{ backgroundImage: 'url(' + imageUrl + ')' }"
                :id="xrayType == XRAY_TYPE_SPINE ? 'gd_copy_up' : ''">
                <img v-if="!imgLoading && xrayMap && xrayMap.fileUrl" :src="xrayMap.fileUrl + xrayMap?.modThumbNm" />
                <div v-if="!imgLoading && xrayMap && !xrayMap.fileUrl" class="upload-text">
                    파일 업로드<br />
                    (dcm, jpg, png)<br>
                    <span v-if="xrayType === XRAY_TYPE_HANDS" class="fileUploadNotice">※ 좌측 수부 이미지만 등록하여야 합니다.</span>
                    <span v-else class="fileUploadNotice">※ Whole Spine 정면 이미지만 등록하여야 합니다.</span>
                </div>
                <Loading size="32" :text="messages" :isShow="imgLoading" />
            </div>
        </div>
    </v-layout>
</template>

<script>
import EventBus from '../plugins/EventBus';
import API_UPLOAD from '../API/reading/upload';
import API_UPLOAD_DCM from '../API/reading/uploadDcm';

export default {
    components: {},
    props: {
        xrayType: {
            default: '',
        },
        xrayNm: {
            default: '',
        },
        uploadComplete: {
            default: () => { }
        },
        removeFileToParent: {
            default: () => { }
        },
        xrayMap: {
            default() {
                return {};
            },
        },
        showPictureDt: {
            default: true
        },
        pictureDtProps: {
            default: ''
        }
    },
    data() {
        return {
            selectedFile: undefined,
            selectedFileName: '', // 선택한 파일 이름을 저장하는 데이터 속성 추가
            messages: '이미지를 업로드 중입니다...',
            imgLoading: false,
            imageUrl: null,
            pictureDt: this.pictureDtProps,
            pineDx: process.env.VUE_APP_PINE_DX,
            checkPatient: true
        };
    },
    watch: {
        pictureDt(value) {
            this.pictureDt = value;
        }
    },
    computed: {},
    created() { },
    mounted() {
        if (this.xrayType == this.XRAY_TYPE_SPINE) this.$emit('loaded');
        EventBus.on('resetImage', this.resetImage); // 이벤트 리스너 추가
        if(localStorage.getItem('selectedFileName')) this.selectedFileName = localStorage.getItem('selectedFileName')
    },
    destroyed() {
        EventBus.off('resetImage', this.resetImage); // 이벤트 리스너 해제
    },
    methods: {
        fileUploadEvent() {
            if (this.xrayMap && this.xrayMap.no) return;
            else this.$refs.fileInput.click();
        },
        async handleFileUpload(event) {
            const uploaded = event.target.files[0];
            const allowedExtensions = ['.jpg', '.png', '.dcm'];
            const fileExtension = uploaded.name.split('.').pop().toLowerCase();
            if (!allowedExtensions.includes(`.${fileExtension}`)) {
                this.showPopup('허용되지 않는 파일 확장자입니다.');
                event.target.value = '';
                return;
            }
            this.selectedFile = uploaded;
            this.selectedFileName = uploaded.name; // 파일 이름 저장
            localStorage.setItem("selectedFileName", this.selectedFileName);
            this.imgLoading = true;
            let res = null;
            if (fileExtension.includes("dcm")) {
                res = await API_UPLOAD_DCM.request(this.selectedFile, this.xrayType);
            } else {
                res = await API_UPLOAD.request(this.selectedFile, this.xrayType);
            }
            if (res.isSuccess) {
                this.checkPatient = true;
                if (res.patientObj) {
                    const targetInfo = JSON.parse(localStorage.getItem('targetInfo'));
                    if (targetInfo && targetInfo.patientSn !== res.patientObj.patientSn) {
                        this.showPopup('환자 정보가 일치하지 않습니다.<br/>다시 확인하세요.');
                        this.checkPatient = false;
                    } else {
                        res.patientObj.isDicom = true;
                        localStorage.setItem("targetInfo", JSON.stringify(res.patientObj));
                        this.$emit('updateTargetInfo', res.patientObj);
                    }
                }
                if (this.checkPatient) {
                    this.uploadComplete(this.xrayType, res.fileObj);
                    if (res.resultObj) {
                        this.pictureDt = res.resultObj.pictureDtStr;
                    } else {
                        this.pictureDt = this.getToDay();
                    }
                    localStorage.setItem("pictureDt", this.pictureDt);
                    this.showToast('업로드 완료');
                }
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.imgLoading = false;
        },
        removeFile() {
            this.$refs.fileInput.value = '';
            this.selectedFile = undefined;
            this.selectedFileName = ''; // 파일 이름 초기화
            localStorage.removeItem('selectedFileName')
            this.removeFileToParent(this.xrayType);
        },
        async showClipboardImage() {
            if (!navigator.clipboard || !navigator.clipboard.read) {
                this.showToast('클립보드를 지원하지 않는 환경입니다.');
                return;
            }
            if (this.imgLoading) return;
            this.imgLoading = true;
            if (this.selectedFile) this.removeFile();
            try {
                const clipboardItems = await navigator.clipboard.read();
                for (const clipboardItem of clipboardItems) {
                    for (const type of clipboardItem.types) {
                        let res = null;
                        const blob = await clipboardItem.getType(type);
                        if (type.startsWith('image/')) {
                            res = await API_UPLOAD.request(blob, this.xrayType, 'copyImage.jpg');
                        } else {
                            res = await API_UPLOAD_DCM.request(blob, this.xrayType, 'copyImage.dcm');
                        }

                        if (res.isSuccess) {
                            this.uploadComplete(this.xrayType, res.fileObj);
                        } else {
                            this.showPopup(res.errorMsg, res.status);
                        }
                    }
                }
            } catch (error) {
                console.error('클립보드 읽기 실패:', error);
            }
            this.imgLoading = false;
        },
        resetImage() {
            this.removeFile(); // 선택된 이미지 초기화
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";

.box-group {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.box:last-child {
    margin-right: 0;
}
.box-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.box-title {
    position: relative;
    color: var(--color-s80);
    padding-left: 24px;
    font-weight: 600;
    font-size: 24px;
}
.box-title::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    border-radius: 100px;
    background: var(--color-s80);
}
.upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px;
    background-color: #fff;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    height: 32px;
    width: 120px;
}
.upload-button .button-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-point);
}
.upload-button .button-icon {
    display: flex;
    align-items: center;
}
.upload-button .button-icon img {
    width: 18px;
    height: 18px;
}
.change-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 16px;
    cursor: pointer;
    height: 32px;
    width: 90px;
    margin-left: 8px;
}
.change-button .button-text {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}
.file-and-date {
    display: flex;
    align-items: center;
}
.file-name {
    display: flex;
    align-items: center;
    margin-right: 16px;
}
.fileLabel {
    margin-right: 8px;
    color: #e0e0e0e1;
    font-size: 14px;
}
.file-name-background {
    padding: 8px;
    background-color: #f9f9f92f;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    min-width: 150px; /* 최소 너비를 설정합니다. */
    display: flex;
    justify-content: center; /* 텍스트를 중앙 정렬합니다. */
}
.dateInpW {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #fff;
}
.dateLabel {
    opacity: .8;
    font-size: 1.4rem;
    font-weight: 300;
    color: #e0e0e0e1;
}
.dateInp {
    position: relative;
    padding: 0 8px;
    font-size: 1.4rem;
    width: 160px;
    background: url(../assets/images/calendar_today.svg) no-repeat right 8px center;
    background-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    height: 32px;
    border-radius: 4px;
}
.dateInp::-webkit-calendar-picker-indicator {
    position: absolute;
    right: -8px;
    top: 0;
    width: 16px;
    height: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
}
.box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    background-color: #111;
    cursor: pointer;
}
.box-content:focus {
    border-color: var(--color-s80)
}
.upload-text {
    position: relative;
    padding-top: 120px;
    text-align: center;
    font-size: 20px;
    color: #777;
    line-height: 1.5em;
    transition: transform 0.5s ease;
    transform: scale(1);
}
.upload-text::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100px;
    height: 90px;
    background: url(../assets/images/fileUpload.svg) no-repeat 50% 50%;
    background-size: contain;
}
.box-content img {
    transition: transform 0.3s ease;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
}
.box-content:hover .upload-text {
    transition: transform 0.5s ease;
    transform: scale(1.03);
}

.fileUploadNotice {
    font-size: 16px;
    padding-top: 15px;
    display: inline-block;
    font-weight: bold;
}

@include desktop {
    .dateInpW {
        .dateLabel {
            display: none;
        }
        .dateInp {
            width: 130px;
        }
    }
}

@include smDesktop {
    .box-title {
        font-size: 22px;
        .br {
            display: block;
            padding-top: 1.125rem;
        }
    }
}
</style>
